import React, { useEffect, useState } from "react";
// import { Dashboard } from "./Dashboard";
import axios from "axios";
import { AUTH_TOKEN, USER_DETAILS, baseURl } from "../../../utils";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { AutoGeneateToken } from "./AutoGeneateToken";

export const TokenCreate = ({
  doctor_id = null,
  urls,
  is_last = true,
  payment = "no",
  shiftId = null,
}) => {
  const active_shift_id = localStorage.getItem("shift_id");
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState(null);
  const [today, setToday] = useState(null);
  const [lastToken, setLastToken] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [shift_id, setShiftId] = useState(null);
  const [stafflist, setStafflist] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    date: "",
    name: "",
    dob: "",
    gender: "Male",
    mobile: "",
    location: "",
    referral: "",
    shift_id:
      localStorage.getItem("shift_id") ??
      (USER_DETAILS && USER_DETAILS.active_shift_id),
  });
  const [permissions, setPermissions] = useState({
    autogenerate: 0,
    edit: 0,
    delete: 0,
    assign: 0,
  });
  const userPer = JSON.parse(localStorage.getItem("role_permissions") || "[]");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name == "mobile" && value.length >= 10) {
      checkMobile(value);
    }

    if (name == "shift_id") {
      getLastToken(value);
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      doctor_id: doctor_id,
      shift_id:
        shiftId != null
          ? shiftId
          : e.target.shift_id
          ? e.target.shift_id.value
          : shift_id,
      date: e.target.date ? e.target.date.value : formData.date,
      name: e.target.name.value,
      dob: e.target.dob.value,
      gender: e.target.gender.value,
      mobile: e.target.mobile.value,
      email: e.target.location.value,
      referral: e.target.referral ? e.target.referral.value : null,
    };
    setIsLoading(true);
    const url = urls.addToken;
    axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      })
      .then((response) => {
        if (payment == "yes") {
          if (response.data && response.data.data) {
            window.location.href = response.data.data;
          }
        }
        Swal.fire({
          icon: "success",
          title: `Token Number: ${response.data.data.token_number}`,
          text: `Time - ${response.data.data.expected_time}`,
        });
        const setData = {
          date: formData.date,
          name: "",
          dob: "",
          gender: "Male",
          mobile: "",
          email: "",
          referral: "",
          shift_id: formData.shift_id,
        };
        getLastToken(formData.shift_id);
        setFormData(setData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error adding token", error.response.data.message);
        // Use SweetAlert to display an error message
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error?.response?.data?.message,
        });
        setIsLoading(false);
      });
  };

  const checkMobile = (mobile) => {
    const data = {
      doctor_id: doctor_id,
      mobile: mobile,
      shift_id: formData.shift_id,
    };
    axios
      .post(urls.mobileCheck, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      })
      .then((response) => {
        const resData = response.data.data || [];
        const setData = {
          doctor_id: doctor_id,
          shift_id: formData.shift_id,
          date: formData.date,
          name: resData.name || "",
          dob: resData.dob || "",
          gender: resData.gender || formData.gender,
          mobile: mobile,
          location: resData.email || "",
          referral: resData.referral || "",
        };
        if (setData.name != "") {
          //setData.patient_type = "Old Patient";
          setType("Old Patient");
        } else {
          //setData.patient_type = "New Patient";
          setType("New Patient");
        }

        setFormData(setData);
      })
      .catch((error) => {
        console.error("Error adding token");
      });
  };

  const getLastToken = (shift_id) => {
    axios
      .post(
        urls.lastToken,
        { shift_id: shift_id, doctor_id: doctor_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      )
      .then((response) => {
        const data = response.data.data;
        setLastToken(data);
      })
      .catch((error) => {
        //console.error("Error fetching data", error);
        setIsLoading(false);
      });
  };

  const fatchShiftList = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        urls.listShift,
        { doctor_id: doctor_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        setShifts(response.data.data);
        if (response.data.data.length == 1) {
          localStorage.setItem("shift_id", response.data.data[0].id);
          setShiftId(response.data.data[0].id);
          getLastToken(response.data.data[0].id);
        }
        setIsLoading(false);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching the API:", error);
    }
  };

  const ReferralList = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${baseURl}/doctor/employee/list`,
        { doctor_id: doctor_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        setStafflist(response.data.data);
        setIsLoading(false);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching the API:", error);
    }
  };

  const autoGeneate = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    fatchShiftList();
    if (userPer) {
      // console.log(userPer);
      const autoGeneatePermission = userPer.some((perm) => perm.id === 2);
      const editPermission = userPer.some((perm) => perm.id === 42);
      const deletePermission = userPer.some((perm) => perm.id === 43);
      const assignPermission = userPer.some((perm) => perm.id === 44);
      // console.log(addPermission);
      setPermissions({
        autogenerate: autoGeneatePermission,
        edit: editPermission,
        delete: deletePermission,
        assign: assignPermission,
      });
    }
    getLastToken(formData.shift_id);
    setShiftId(active_shift_id);
    ReferralList();
    var today = new Date();
    var day =
      today.getDate().toString().length == 1
        ? "0" + today.getDate()
        : today.getDate();
    var month =
      (today.getMonth() + 1).length == 0
        ? today.getMonth() + 1
        : "0" + (today.getMonth() + 1);
    var todate = today.getFullYear() + "-" + month + "-" + day;
    setFormData({
      ...formData,
      ["date"]: todate,
    });
    setToday(todate);
  }, []);

  return (
    <div className="">
      {lastToken && is_last && (
        <h6 className="bg-website p-3 my-0">
          Last Patient :- {lastToken?.token_number}, {lastToken?.patient?.name}
        </h6>
      )}
      <div className="card card-body p-0" style={{ borderRadius: 0 }}>
        {is_last && (
          <>
            <div className="d-flex justify-content-between p-1 py-2">
              <h6 className="my-0 py-0">Generate Token</h6>
              {permissions.autogenerate > 0 && (
                <button
                  onClick={() => autoGeneate()}
                  className="btn btn-warning py-0 text-white"
                >
                  Auto Generate
                </button>
              )}
            </div>
            <hr className="my-0" />
          </>
        )}
        <form className="py-2 px-3" onSubmit={handleSubmit}>
          <div className="row">
            {is_last && (
              <div className="col-md-6 mb-2">
                <label className="mb-0">Date</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="date"
                  type="date"
                  value={formData.date}
                  placeholder="select date"
                  min={today}
                  pattern="dd-mm-yyyy"
                />
              </div>
            )}
            {shifts.length > 1 && (
              <div className="col-md-6 mb-2" style={{display:shiftId != null ? "none" : ""}}>
                <label className="mb-0">
                  Shift Time <span className="text-danger fs-5">*</span>
                </label>
                <select
                  className="form-control"
                  placeholder="Enter Name"
                  onChange={(e) => handleInputChange(e)}
                  name="shift_id"
                  value={formData.shift_id}
                  required
                >
                  {shifts.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-6 col-sm-12 mb-2">
              <label className="mb-0">
                Mobile <span className="text-danger fs-5">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Mobile Number"
                onChange={(e) => handleInputChange(e)}
                name="mobile"
                maxLength={10}
                minLength={!is_last ? 10 : 1}
                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                value={formData.mobile}
                required
              />
              <h4 className="text-danger h6 mt-2">{type}</h4>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 mb-2">
              <label className="mb-0">
                Name <span className="text-danger fs-5">*</span>
              </label>
              <input
                className="form-control"
                placeholder="Enter Name"
                onChange={(e) => handleInputChange(e)}
                name="name"
                value={formData.name}
                required
              />
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 mb-2">
              <label className="mb-0">
                Age {!is_last && <span className="text-danger fs-5">*</span>}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Age"
                onChange={(e) => handleInputChange(e)}
                name="dob"
                value={formData.dob}
                required={!is_last}
              />
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 mb-2">
              <label className="mb-0">
                Gender <span className="text-danger fs-5">*</span>
              </label>
              <select
                className="form-control"
                onChange={(e) => handleInputChange(e)}
                name="gender"
                required
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
          </div>

          <div className="mb-3">
            <label className="mb-2">
              City/District/Town{" "}
              {!is_last && <span className="text-danger fs-5">*</span>}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Address"
              onChange={(e) => handleInputChange(e)}
              name="location"
              value={formData.location}
              required={!is_last}
            />
          </div>
          {!is_last && (
            <>
              <div className="mb-3">
                <label className="mb-2">Referral</label>
                <select
                  className="form-control"
                  placeholder="Enter Name"
                  onChange={(e) => handleInputChange(e)}
                  name="referral"
                  value={formData.referral}
                >
                  <option value="">select</option>
                  {stafflist &&
                    stafflist.map((item) => (
                      <option value={item.first_name}>{item.name}</option>
                    ))}
                </select>
              </div>
              <div>
                <input
                  type="checkbox"
                  value="check"
                  id="flexCheckDefault"
                  className="text-dark me-2 form-check-input"
                  required
                />
                You agree to our{" "}
                <Link
                  className="ms-2 text-decoration-none"
                  to="/privacy-policy"
                  target="_blank"
                >
                  Terms Of Services{" "}
                </Link>{" "}
                and{" "}
                <Link
                  className="ms-2 text-decoration-none"
                  to="/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
              </div>
            </>
          )}
          <div className="mt-3 text-end">
            <button
              disabled={isLoading}
              className="btn btn-primary ms-2 px-5"
              type="submit"
            >
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              {is_last ? <>Submit</> : <>₹{lastToken.booking_fee} Pay</>}
            </button>
          </div>
        </form>
      </div>
      {today && (
        <AutoGeneateToken
          showModal={showModal}
          autoGeneate={autoGeneate}
          shifts={shifts}
          setShowModal={setShowModal}
          date={formData.date}
          today={today}
        />
      )}
    </div>
  );
};
