import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Button, FormCheck, Form, Card } from "react-bootstrap";
import axios from "axios";
import Header from "./Header";
import { Dashboard } from "./Dashboard";
import { AUTH_TOKEN, BASE_URL } from "../../utils";
import { BiReply } from "react-icons/bi";
import Head from "./components/Head";

const Promissies = () => {
  const navigate = useNavigate();
  const { role_id } = useParams();
  const params = new URLSearchParams(window.location.search);
  const [permissionList, setPermissionList] = useState([]);
  const [roleValue, setRoleValue] = useState([]);

  useEffect(() => {
    listPermission();
  }, []);

  const listPermission = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/permission/list`,
        { role_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      //console.log("Fetched Permissions:", response.data.data);
      const resp = response.data.data;

      setPermissionList(resp.permissions);

      let arrVal = [];
      resp.permissions.forEach((item) => {
        if (item.is_checked) arrVal.push(item.id);
        item.permissions.forEach((itm) => {
          if (itm.is_checked) arrVal.push(itm.id);
          itm.permissions.forEach((it) => {
            if (it.is_checked) arrVal.push(it.id);
          });
        });
      });
      setRoleValue(arrVal);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const onFinish = () => {
    const uniqueRoleValue = [...new Set(roleValue)];
    const data = { role_id: role_id, permission_id: uniqueRoleValue };

    try {
      const response = axios.post(`${BASE_URL}/role/permission/assign`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      });
    } catch (error) {
      alert(error.response?.data?.message || "An error occurred");
    }
  };

  const onFinishValue = (permission_id) => (e) => {
    if (e.target.checked) {
      setRoleValue((prev) => [...prev, permission_id]);
    } else {
      setRoleValue((prev) => prev.filter((id) => id !== permission_id));
    }
  };

  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "60px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <div className="main-container my-3 px-lg-3 px-1">
            <div className="">
              <div className="w-100">
                <Head />
                <div className="card card-body">
                  <h3>Role - {params.get("name")}</h3>
                  <hr className="mb-lg-3" />
                  <div className="text-right mb-3">
                    <Button className="px-5" type="primary" onClick={onFinish}>
                      Save
                    </Button>
                    <button
                      className="btn btn-info ml-2 ms-2"
                      onClick={() => navigate(`/admin/role`)}
                      title="Back"
                    >
                      <BiReply /> Back
                    </button>
                  </div>
                  {permissionList &&
                    permissionList.map((item) => {
                      return (
                        <div key={`${item.id}`}>
                          <Row key={item.id}>
                            <Col xs={12} sm={12} md={6} className="mb-2">
                              <div className="w-100">
                                <div className="d-flex justify-content-between">
                                  <h6 className="mb-0">{item.title}</h6>
                                  <FormCheck
                                    type="switch"
                                    id={`switch-${item.id}`}
                                    label=""
                                    defaultChecked={item.is_checked}
                                    onChange={onFinishValue(item.id)}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <div className="px-lg-5">
                            <Row>
                              {item.permissions.map((itm) => {
                                return (
                                  <Col xs={12} sm={12} md={6}>
                                    <div className="d-flex justify-content-between">
                                      <p className="mb-0">{itm.title}</p>
                                      <FormCheck
                                        type="switch"
                                        id={`switch-${itm.id}`}
                                        label=""
                                        defaultChecked={itm.is_checked}
                                        onChange={onFinishValue(itm.id)}
                                      />
                                    </div>
                                    <Row
                                      id=""
                                      className="ps-lg-5 mt-2"
                                      gutter={16}
                                    >
                                      {itm.permissions.map((it) => {
                                        return (
                                          <Col
                                            xs={24}
                                            sm={24}
                                            md={12}
                                            className="mb-3"
                                            key={`chield${it.id}`}
                                          >
                                            <div className="d-flex justify-content-between">
                                              <p className="mb-0">{it.title}</p>
                                              <FormCheck
                                                type="switch"
                                                id={`switch-${it.id}`}
                                                label=""
                                                defaultChecked={it.is_checked}
                                                onChange={onFinishValue(it.id)}
                                              />
                                            </div>
                                          </Col>
                                        );
                                      })}
                                    </Row>
                                  </Col>
                                );
                              })}
                            </Row>
                          </div>
                        </div>
                      );
                    })}

                  <div className="text-right">
                    <Button className="px-5" type="primary" onClick={onFinish}>
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promissies;
